import React, { FC, useState } from 'react'
import { Picker, List, Popover } from 'antd-mobile'
import classnames from 'classnames'
import { formats } from '@/pages/index'
import styles from './index.module.scss'
import useTranslation from 'next-translate/useTranslation'
import { saClick } from '../../utils/sensors'

interface FormatListProps {
  formatIndex: number
  setFormatIndex: React.Dispatch<React.SetStateAction<number>>
}

const FormatList: FC<FormatListProps> = ({ formatIndex, setFormatIndex }) => {
  const { t }: { t: I18nType } = useTranslation('common')
  const [tipVisible, setTipVisible] = useState(false)

  const _formats = formats.map(item => {
    return {
      ...item,
      label: t(item.label as any),
    }
  })

  return (
    <div className={styles.formatBox}>
      {/* web端 */}
      <div className={styles.content}>
        <div className={styles.formatOptions}>
          <div className={styles.formatLabel}>
            <Popover
              visible={tipVisible}
              overlay={
                <Popover.Item className={styles.popoverItem}>
                  <div className={styles.tips}>{t('Convert tips')}</div>
                </Popover.Item>
              }
              placement={'bottomLeft'}
            >
              <div
                className="iconfont icon-tips-default iconTips"
                onMouseEnter={() => setTipVisible(true)}
                onMouseLeave={() => setTipVisible(false)}
              ></div>
            </Popover>
            {t('Convert to')}
          </div>
          <div className={styles.formatValues}>
            {formats.map((v, index) => (
              <div
                key={index}
                onClick={() => {
                  setFormatIndex(index)
                  saClick('core', 'format', `${index + 1}`)
                }}
                className={classnames({
                  [styles.formatValue]: true,
                  [styles.active]: index === formatIndex,
                })}
              >
                {t(v.label as I18nItemType)}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* mobile */}
      <div className={styles.mContent}>
        <Picker
          className={styles.picker}
          data={_formats}
          cols={1}
          value={[_formats[formatIndex].value]}
          okText="Ok"
          dismissText={t('Cancel')}
          onChange={val => {
            if (!val) return
            const index = _formats.findIndex(e => e.value === val[0])
            setFormatIndex(index)

            saClick('core', 'format', `${index + 1}`)
          }}
        >
          <List.Item arrow="horizontal" className={styles.listItem}>
            {t('Convert to')}
          </List.Item>
        </Picker>
      </div>
    </div>
  )
}

export default FormatList
