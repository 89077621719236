import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
// @ts-ignore

import styles from './index.module.scss'

type listItem = {
  name: CommonType
  enUrl: string
  ptUrl: string
  esUrl: string
}

type listItemKey = keyof listItem

export const onlineConverterUrlList: listItem[] = [
  {
    name: 'Video to MP3',
    enUrl: 'https://www.online-audioconvert.com/video-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/video-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/video-to-mp3/',
  },
  {
    name: 'Audio to MP3',
    enUrl: 'https://www.online-audioconvert.com/audio-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/audio-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/audio-to-mp3/',
  },
]

export const supportUrlList: listItem[] = [
  {
    name: 'FAQ',
    enUrl: 'https://www.online-audioconvert.com/faq/',
    ptUrl: 'https://www.online-audioconvert.com/pt/faq/',
    esUrl: 'https://www.online-audioconvert.com/es/faq/',
  },
  // {
  //   name: 'Blog',
  //   enUrl: 'https://www.online-audioconvert.com/tutorials/',
  //   ptUrl: 'https://www.online-audioconvert.com/tutorials-pt/',
  //   esUrl: 'https://www.online-audioconvert.com/tutorials-es/',
  // },
]

const Footer: FC = () => {
  const { t, lang }: { t: I18nType; lang: string } = useTranslation('common')

  return (
    <footer className={styles.footer}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <img src="https://www.online-audioconvert.com/convert-logo.png" alt="" />
          <p>Online AudioConvert</p>
        </div>
        <div className={styles.nav}>
          <p className={styles.title}>{t('Online Converter')}</p>
          {onlineConverterUrlList.map((item, index) => (
            <div key={index} className={styles.list}>
              <a href={item[(lang + 'Url') as listItemKey]}>{t(item.name)}</a>
            </div>
          ))}
        </div>
        <div className={styles.nav}>
          <p className={styles.title}>{t('Support')}</p>
          {supportUrlList.map((item, index) => (
            <div key={index} className={styles.list}>
              <a href={item[(lang + 'Url') as listItemKey]}>{t(item.name)}</a>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.copyright}>Copyright © {new Date().getFullYear()} online-audioconvert</div>
    </footer>
  )
}

export default Footer
