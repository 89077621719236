import React, { DragEventHandler, FC, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import FileItem from './components/FileItem'
import { eventBus } from '@/utils/eventBus'

import styles from './index.module.scss'
import { saClick } from '../../utils/sensors'

type FileUploadProps = {
  fileList: File[]
  onChange: (files: FileList) => void
  setFileList: React.Dispatch<React.SetStateAction<File[]>>
  onConvert: () => void
}

const FileUpload: FC<FileUploadProps> = ({ fileList, onChange, setFileList, onConvert }) => {
  const { t }: { t: I18nType } = useTranslation('common')
  const [showDrop, setShowDrop] = useState(false)
  const chooseRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [showWarnTips, setShowWarnTips] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    eventBus.on('file-warn-tips', eventListener)

    setIsMobile(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
        navigator.userAgent
      )
    )

    return () => {
      eventBus.removeListener('file-warn-tips', eventListener)
    }
  }, [])

  useEffect(() => {
    if (showWarnTips) {
      setTimeout(() => {
        eventBus.emit('file-warn-tips', false)
      }, 3000)
    }
  }, [showWarnTips])

  function eventListener(bool: boolean) {
    setShowWarnTips(bool)
  }

  const handleClick = () => {
    fileInputRef.current?.click()

    saClick('core', fileList.length ? 'upload' : 'local', fileList.length ? 'upload' : 'choose')
  }

  const handleSelect = () => {
    const select = fileInputRef.current
    if (select) {
      handleFile(select.files)
    }
  }

  const dragEnter: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    chooseRef.current?.classList.add('dragover')
    setShowDrop(true)
  }

  const dragLeave: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    chooseRef.current?.classList.remove('dragover')
    setShowDrop(false)
  }

  const dragOver: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const drop: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    const fileList = e.dataTransfer.files
    chooseRef.current?.classList.remove('dragover')
    setShowDrop(false)
    handleFile(fileList)

    saClick('core', 'local', 'drop')
  }

  const handleFile = (e: FileList | null) => {
    if (!e) return
    onChange(e)

    for (let i = 0; i < e.length; i++) {
      const el = e[i]
      if (!el.type.startsWith('audio/') && !el.type.startsWith('video/')) {
        eventBus.emit('file-warn-tips', true)
      }
    }
  }

  return (
    <div className={styles.fileUpload}>
      {!isMobile ? (
        <div className={styles.content}>
          {!fileList.length && (
            <div
              className={styles.dropArea}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDragOver={dragOver}
              onDrop={drop}
            >
              <div className={styles.chooseFile} ref={chooseRef}>
                <div className={styles.chooseFileContent} onClick={handleClick}>
                  <img className={styles.icon} src={require('./image/ic_local.png').default} alt="icon" />
                  <div className={styles.text}>{t(fileList.length ? 'Upload More' : 'Choose Local Files')}</div>
                </div>
                <input
                  type="file"
                  multiple
                  className={styles.fileInput}
                  ref={fileInputRef}
                  onChange={handleSelect}
                  accept="audio/*,video/*"
                />
              </div>
              <div className={styles.dropText}>{t('Or Drag Files Here')}</div>
            </div>
          )}
          {showWarnTips ? (
            <div className={styles.warnTipsWrap}>
              <div className={styles.warnTips}>
                <img className={styles.icon} src={require('./image/ic_warn.png').default} alt="" />
                {t('Some files wrong')}
              </div>
            </div>
          ) : null}
          <div className={styles.fileList}>
            {fileList.map((e, i) => (
              <FileItem
                key={`${e.name}-${e.size}`}
                name={e.name}
                size={e.size}
                file={e}
                onDelete={() => {
                  const flist = Array.from(fileList)
                  flist.splice(i, 1)
                  setFileList(flist)
                }}
              />
            ))}
          </div>
          {!!fileList.length && (
            <div className={styles.operation}>
              <div className={`${styles.btn} ${styles.addMore}`} onClick={handleClick}>
                <div className="iconfont icon-plus"></div>
                {t('Add More Files')}
              </div>
              <div className={`${styles.btn} ${styles.convert}`} onClick={onConvert}>
                <img className={styles.icon} src={require('../../image/ic_convert_w.png').default} alt="convert" />
                {t('Start to Convert')}
              </div>
            </div>
          )}
          {!!fileList.length && (
            <input
              style={{ opacity: 0 }}
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleSelect}
              accept="audio/*,video/*"
            />
          )}
        </div>
      ) : (
        <div className={styles.mContent}>
          {!!fileList.length ? (
            <div ref={chooseRef} className={styles.addFile}>
              <div className={styles.chooseFileContent} onClick={handleClick}>
                <div className="iconfont icon-plus"></div>
                <div className={styles.text}>{t('Add More Files')}</div>
              </div>
              <input
                type="file"
                multiple
                className={styles.fileInput}
                ref={fileInputRef}
                onChange={handleSelect}
                accept="audio/*,video/*"
              />
            </div>
          ) : (
            <div ref={chooseRef} className={styles.chooseFile}>
              <div className={styles.chooseFileContent} onClick={handleClick}>
                <img className={styles.icon} src={require('./image/ic_local1.png').default} alt="icon" />
                <div className={styles.text}>{t(fileList.length ? 'Upload More' : 'Choose Files')}</div>
              </div>
              <input
                type="file"
                multiple
                className={styles.fileInput}
                ref={fileInputRef}
                onChange={handleSelect}
                accept="audio/*,video/*"
              />
            </div>
          )}
          <div className={styles.fileList}>
            {fileList.map((e, i) => (
              <FileItem
                key={`${e.name}-${e.size}`}
                name={e.name}
                size={e.size}
                file={e}
                onDelete={() => {
                  const flist = Array.from(fileList)
                  flist.splice(i, 1)
                  setFileList(flist)
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FileUpload
