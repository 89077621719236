import React, { FC } from 'react'
import Menu, { SubMenu, MenuItem } from 'rc-menu'
import useTranslation from 'next-translate/useTranslation'
import { androidToolsUrlList, listItemKey, onlineToolsUrlList } from '../..'
import { videoUrlList, audioUrlList, goto } from '../DropList'
import styles from './index.module.scss'
import 'rc-menu/assets/index.css'

type SideBarProps = {
  isLang: boolean
}

const SideBar: FC<SideBarProps> = ({ isLang }) => {
  const { t, lang }: { t: I18nType; lang: string } = useTranslation('common')

  return (
    <div className={styles.sideBar} onClick={e => e.stopPropagation()}>
      <Menu
        mode="inline"
        // @ts-ignore
        inlineIndent={0}
        expandIcon={({ isOpen }) => {
          return (
            <img
              style={{
                marginRight: 11,
                width: isOpen ? 7 : 5,
              }}
              src={require(`./image/ic_${isOpen ? 'open' : 'close'}.png`).default}
              alt=""
            />
          )
        }}
      >
        {!isLang ? (
          <>
            <SubMenu key="1-1" title={t('Android Tools')}>
              {androidToolsUrlList.map((e, i) => (
                <MenuItem key={`1-1-${i + 1}`}>
                  <div className={styles.submenuItem} key={i}>
                    <a href={e[`${lang}Url` as listItemKey]} rel="noreferrer" target="_blank">
                      {t(e.name)}
                    </a>
                  </div>
                </MenuItem>
              ))}
            </SubMenu>
            <SubMenu key="1-2" title={t('Online Tools')}>
              {onlineToolsUrlList.map((e, i) => (
                <MenuItem key={`1-2-${i + 1}`}>
                  <div className={styles.submenuItem} key={i}>
                    <a href={e[`${lang}Url` as listItemKey]} rel="noreferrer" target="_blank">
                      {t(e.name)}
                    </a>
                  </div>
                </MenuItem>
              ))}
            </SubMenu>
            <MenuItem key={`1-3`}>
              <div className={styles.submenuItem}>
                <div className={styles.video}>
                  <div className={styles.title}>{t('Video to MP3')}</div>
                  {videoUrlList.map((item, index) => (
                    <div key={index} className={styles.list}>
                      <a className={styles.list} href={item[(lang + 'Url') as listItemKey]}>
                        {t(item.name)}
                      </a>
                    </div>
                  ))}
                </div>
                <div className={styles.mp3}>
                  <div className={styles.title}>{t('Audio to MP3')}</div>
                  {audioUrlList.map((item, index) => (
                    <div key={index} className={styles.list}>
                      <a className={styles.list} href={item[(lang + 'Url') as listItemKey]}>
                        {t(item.name)}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem key={`1-3-1`}>
              <div className={styles.menuItem}>
                <a href="/">English</a>
              </div>
            </MenuItem>
            <MenuItem key={`1-3-2`}>
              <div className={styles.menuItem}>
                <a href="/pt/">Português</a>
              </div>
            </MenuItem>
            <MenuItem key={`1-3-3`}>
              <div className={styles.menuItem}>
                <a href="/es/">Español</a>
              </div>
            </MenuItem>
          </>
        )}
      </Menu>
    </div>
  )
}

export default SideBar
