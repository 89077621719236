import React, { FC, useEffect } from 'react'
import useTranslation from 'next-translate/useTranslation'
import styles from './index.module.scss'
import { saClick } from '@/pagesComp/index/utils/sensors'

type ModalProps = {
  onOk: () => void
  onClose: () => void
  title: string
  content: string
}

const Modal: FC<ModalProps> = ({ onOk, onClose, title, content }) => {
  const { t }: { t: I18nType } = useTranslation('common')

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const okHandler: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
    onOk()

    saClick('toast', 'wrong', 'ok')
  }

  const closeHandler: React.MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
    onClose()
  }

  return (
    <div className={styles.modal} onClick={closeHandler}>
      <div className={styles.body}>
        {/* <img className={styles.icon} src={require('./image/ic_error.png').default} alt="" /> */}
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{content}</div>
        <div className={styles.btn} onClick={okHandler}>
          {t('OK')}
        </div>
      </div>
    </div>
  )
}

export default Modal
