import axios from 'axios'

const instance = axios.create({
  // baseURL: process.env.NEXT_PUBLIC_REQUEST_HOST,
  timeout: 60000,
})

// 拦截器
instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  response => {
    const json = response.data

    if (json.success === true) {
      return json
    }
    const err: any = new Error(json.message || json.errMsg)
    err.response = json
    return Promise.reject(err)
  },
  error => {
    if (axios.isCancel(error)) {
      error.isCancel = true
    }
    return Promise.reject(error)
  }
)

export default instance
