function formatFileSize(fileSize: number) {
  if (fileSize < 1024) {
    return fileSize + ' B'
  } else if (fileSize < 1024 * 1024) {
    const temp = fileSize / 1024
    return temp.toFixed(2) + ' KB'
  } else if (fileSize < 1024 * 1024 * 1024) {
    const temp = fileSize / (1024 * 1024)
    return temp.toFixed(2) + ' MB'
  } else {
    const temp = fileSize / (1024 * 1024 * 1024)
    return temp.toFixed(2) + ' GB'
  }
}

export { formatFileSize }
