import React, { FC, useState } from 'react'
import classnames from 'classnames'
import useTranslation from 'next-translate/useTranslation'
// @ts-ignore
import JsCookie from 'js-cookie'
import SideBar from './components/SideBar'
import DropList from './components/DropList'

import styles from './index.module.scss'
import { saClick } from '../../utils/sensors'
import io from '@/utils/io'
import ListItem from 'antd-mobile/lib/list/ListItem'

type listItem = {
  name: CommonType
  enUrl: string
  ptUrl: string
  esUrl: string
}

export type listItemKey = keyof listItem

export const androidToolsUrlList: listItem[] = [
  {
    name: 'YouTube Downloader',
    enUrl: 'https://www.snaptubeapp.com/',
    ptUrl: 'https://www.snaptubeapp.com/pt/',
    esUrl: 'https://www.snaptubeapp.com/es/',
  },
  {
    name: 'Movie Downloader',
    enUrl: 'https://www.snaptubeapp.com/how-to/movies/',
    ptUrl: 'https://www.snaptubeapp.com/how-to-pt/download-movies/',
    esUrl: 'https://www.snaptubeapp.com/how-to-es/movies/',
  },
  {
    name: 'All in One Video Downloader',
    enUrl: 'https://www.snaptubeapp.com/how-to',
    ptUrl: 'https://www.snaptubeapp.com/how-to-pt/',
    esUrl: 'https://www.snaptubeapp.com/how-to-es/',
  },
  {
    name: 'Multiple Platform Converter',
    enUrl: 'https://www.snaptubeapp.com/how-to/music/video-to-MP3.html',
    ptUrl: 'https://www.snaptubeapp.com/how-to-pt/download-music/video-to-audio-converter.html',
    esUrl: 'https://www.snaptubeapp.com/how-to-es/video-downloader/convert-video.html',
  },
  {
    name: 'Vimeo Downloader',
    enUrl: 'https://www.snaptubeapp.com/how-to/videos/MP4-downloader-vimeo.html',
    ptUrl: 'https://www.snaptubeapp.com/how-to-pt/download-facebook-videos/download-audio-from-facebook.html',
    esUrl: 'https://www.snaptubeapp.com/how-to-es/video-downloader/descargar-vimeo-video.html',
  },
  {
    name: 'Vimeo to MP3 Converter',
    enUrl: 'https://www.snaptubeapp.com/how-to/videos/vimeo-to-MP3.html',
    ptUrl: 'https://www.snaptubeapp.com/how-to-pt/download-instagram-videos/instagram-to-mp3-converter.html',
    esUrl: 'https://www.snaptubeapp.com/how-to-es/video-downloader/vimeo-to-mp3.htmll',
  },
  {
    name: 'Vmate Downloader',
    enUrl: 'https://vmateapp.com/',
    ptUrl: 'https://vmateapp.com/',
    esUrl: 'https://vmateapp.com/',
  },
]

export const onlineToolsUrlList: listItem[] = [
  {
    name: 'Web YouTube Downloader',
    enUrl: 'https://www.snappea.com/',
    ptUrl: 'https://www.snappea.com/pt1/',
    esUrl: 'https://www.snappea.com/es2/',
  },
  {
    name: 'Web YouTube Converter',
    enUrl: 'https://www.snappea.com/tutorials/convert-YouTube/',
    ptUrl: 'https://www.snappea.com/tutorials-pt/convert-YouTube/',
    esUrl: 'https://www.snappea.com/tutorials-es/convert-youtube/',
  },
  {
    name: 'YouTube MP3 Converter',
    enUrl: 'https://www.snappea.com/tutorials/yt-to-MP3/',
    ptUrl: 'https://www.snappea.com/tutorials-pt/yt-to-MP3/',
    esUrl: 'https://www.snappea.com/tutorials-es/ytb-to-mp3/',
  },
  {
    name: 'YouTube MP4 Converter',
    enUrl: 'https://www.snappea.com/tutorials/YouTube-to-MP4/',
    ptUrl: 'https://www.snappea.com/tutorials-pt/yt-to-MP4/',
    esUrl: 'https://www.snappea.com/tutorials-es/yt-to-mp4/',
  },
  {
    name: 'YouTube Music Downloader',
    enUrl: 'https://www.snappea.com/tutorials/download-YouTube-songs/',
    ptUrl: 'https://www.snappea.com/tutorials-pt/download-yt-music/',
    esUrl: 'https://www.snappea.com/tutorials-es/download-youtube-music/',
  },
]

const langList: listItem[] = [
  {
    name: 'en',
    enUrl: '/',
    ptUrl: '/',
    esUrl: '/',
  },
  {
    name: 'pt',
    enUrl: '/pt/',
    ptUrl: '/pt/',
    esUrl: '/pt/',
  },
  {
    name: 'es',
    enUrl: '/es/',
    ptUrl: '/es/',
    esUrl: '/es/',
  },
]

const Header: FC = () => {
  const { t, lang }: { t: I18nType; lang: string } = useTranslation('common')
  const [isShowNav1, setIsShowNav1] = useState(false)
  const [isShowNav2, setIsShowNav2] = useState(false)
  const [isShowNav3, setIsShowNav3] = useState(false)
  const [isShowToolSecondNav, setIsShowToolSecondNav] = useState(false)
  const [urlList, setUrlList] = useState<listItem[]>([])
  const [isOpen, setIsOpen] = useState(false)
  const [isLang, setIsLang] = useState(false)

  const navHandler = (type: 0 | 1 | 2) => {
    if (type === 0) {
      if (!isShowNav1) {
        setIsShowNav1(true)
        setIsShowNav2(false)
        setIsShowNav3(false)
        setUrlList(androidToolsUrlList)
      } else {
        setIsShowNav1(false)
        setIsShowNav2(false)
        setIsShowNav3(false)
        setUrlList([])
      }
      saClick('head', 'tool1', 'tool1')
    } else if (type === 1) {
      if (!isShowNav2) {
        setIsShowNav1(false)
        setIsShowNav2(true)
        setIsShowNav3(false)
        setUrlList(onlineToolsUrlList)
      } else {
        setIsShowNav1(false)
        setIsShowNav2(false)
        setIsShowNav3(false)
        setUrlList([])
      }
      saClick('head', 'tool2', 'tool2')
    } else {
      if (!isShowNav3) {
        setIsShowNav1(false)
        setIsShowNav2(false)
        setIsShowNav3(true)
        setUrlList(langList)
      } else {
        setIsShowNav1(false)
        setIsShowNav2(false)
        setIsShowNav3(false)
        setUrlList([])
      }
      saClick('head', 'languages', 'languages')
    }
  }

  const navToolHandler = () => {
    setIsShowToolSecondNav(!isShowToolSecondNav)
  }

  const openDrawer = () => {
    if (isOpen && isLang) {
      setIsLang(false)
    } else if (isOpen && !isLang) {
      setIsOpen(false)
      document.body.style.overflow = 'auto'
      setIsLang(false)
      saClick('head', 'navList', 'close')
    } else if (!isOpen && !isLang) {
      setIsOpen(true)
      document.body.style.overflow = 'hidden'
      saClick('head', 'navList', 'open')
      // setIsLang(true)
    }
  }

  const openLang = () => {
    if (isOpen && !isLang) {
      setIsLang(true)
    } else if (isOpen && isLang) {
      setIsOpen(false)
      document.body.style.overflow = 'auto'
      setIsLang(false)
    } else if (!isOpen && !isLang) {
      setIsOpen(true)
      document.body.style.overflow = 'hidden'
      setIsLang(true)
    }
    saClick('head', 'languages', 'languages')
  }

  const reloadPage = (e: any) => {
    e.preventDefault()

    if (process.browser) {
      const sensorsData = JsCookie.get('sensorsdata2015jssdkcross') || '{}'
      const { distinct_id } = JSON.parse(sensorsData)

      io.post('/api/sensors/track', {
        distinct_id,
        event: 'mp3converting_click',
        property: {
          action: 'head',
          label: 'logo',
          event_name: 'logo',
          index_plan: window.MP3_AB_INDEX_PLAN,
        },
      })

      window.location.href = `https://www.online-audioconvert.com/${lang === 'en' ? '' : `${lang}/`}`
    }
  }

  const goto = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, item: listItem, index: number) => {
    e.preventDefault()

    if (process.browser) {
      const sensorsData = JsCookie.get('sensorsdata2015jssdkcross') || '{}'
      const { distinct_id } = JSON.parse(sensorsData)

      io.post('/api/sensors/track', {
        distinct_id,
        event: 'mp3converting_click',
        property: {
          action: 'head',
          label: isShowNav1 ? 'tool1' : isShowNav2 ? 'tool2' : 'languages',
          event_name: !isShowNav3 ? `${index}` : item.name,
          index_plan: window.MP3_AB_INDEX_PLAN,
        },
      })

      const url = item[`${lang}Url` as listItemKey]

      if (item.name !== 'en' && item.name !== 'pt' && item.name !== 'es') {
        window.open(url)
      } else {
        window.location.href = url.indexOf('en') > -1 ? '/' : url
      }
    }
  }

  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <div className="iconfont icon-menu navIcon" onClick={openDrawer}></div>
        <a
          href={`https://www.online-audioconvert.com/${lang === 'en' ? '' : `${lang}/`}`}
          className={styles.logo}
          onClick={e => reloadPage(e)}
        >
          <img src="/convert-logo.png" alt="" />
          Online AudioConvert
        </a>
        <div className={styles.navItem} onClick={() => navHandler(0)}>
          <div
            className={styles.text}
            style={
              isShowNav1
                ? {
                    color: '#FF5500',
                  }
                : {}
            }
          >
            {t('Android Tools')}
          </div>
          <div
            className={classnames({
              [styles.icon]: true,
              [styles.active]: isShowNav1,
            })}
          ></div>
        </div>
        <div className={styles.navItem} onClick={() => navHandler(1)}>
          <div
            className={styles.text}
            style={
              isShowNav2
                ? {
                    color: '#FF5500',
                  }
                : {}
            }
          >
            {t('Online Tools')}
          </div>
          <div
            className={classnames({
              [styles.icon]: true,
              [styles.active]: isShowNav2,
            })}
          ></div>
        </div>
        <div className={styles.navItemTool} onClick={() => navToolHandler()}>
          <div className={styles.text}>{t('Converter')}</div>
          <div className={isShowToolSecondNav ? 'iconfont icon-down active' : 'iconfont icon-down'} />
          {isShowToolSecondNav && <DropList></DropList>}
        </div>

        <div onClick={() => navHandler(2)} className={styles.langIcon}>
          <div className={styles.text}>{t('Language')}</div>
          <div className="iconfont icon-global-default"></div>

          {(isShowNav1 || isShowNav2 || isShowNav3) && (
            <div className={styles.subListWrapper}>
              <div className={styles.subList}>
                {urlList.map((e, i) => (
                  <div className={styles.item} key={i}>
                    <a href={e[`${lang}Url` as listItemKey]} onClick={ev => goto(ev, e, i + 1)}>
                      {t(e.name)}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="iconfont icon-global-default mlangIcon" onClick={openLang}></div>
      </div>
      {isOpen ? (
        <div
          className={styles.mobileSubList}
          onClick={() => {
            setIsOpen(false)
            document.body.style.overflow = 'auto'
            setIsLang(false)
          }}
        >
          <SideBar isLang={isLang} />
        </div>
      ) : null}
    </header>
  )
}

export default Header
