import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import styles from './index.module.scss'

type listItem = {
  name: CommonType
  enUrl: string
  ptUrl: string
  esUrl: string
}

type listItemKey = keyof listItem

export const videoUrlList: listItem[] = [
  {
    name: 'MP4 to MP3',
    enUrl: 'https://www.online-audioconvert.com/mp4-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/mp4-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/mp4-to-mp3/',
  },
  {
    name: 'FLV to MP3',
    enUrl: 'https://www.online-audioconvert.com/flv-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/flv-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/flv-to-mp3/',
  },
  {
    name: 'MOV to MP3',
    enUrl: 'https://www.online-audioconvert.com/mov-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/mov-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/mov-to-mp3/',
  },
  {
    name: 'MPEG to MP3',
    enUrl: 'https://www.online-audioconvert.com/mpeg-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/mpeg-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/mpeg-to-mp3/',
  },
]

export const audioUrlList: listItem[] = [
  {
    name: 'WAV to MP3',
    enUrl: 'https://www.online-audioconvert.com/wav-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/wav-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/wav-to-mp3/',
  },
  {
    name: 'OGG to MP3',
    enUrl: 'https://www.online-audioconvert.com/ogg-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/ogg-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/ogg-to-mp3/',
  },
  {
    name: 'FLAC to MP3',
    enUrl: 'https://www.online-audioconvert.com/flac-to-mp3/',
    ptUrl: 'https://www.online-audioconvert.com/pt/flac-to-mp3/',
    esUrl: 'https://www.online-audioconvert.com/es/flac-to-mp3/',
  },
]

export const goto = (url: string) => {
  window.location.href = url
}

const DropList: FC = () => {
  const { t, lang }: { t: I18nType; lang: string } = useTranslation('common')

  return (
    <div className={styles.navSecond}>
      <div className={styles.video}>
        <div className={styles.title}>{t('Video to MP3 Converter')}</div>
        {videoUrlList.map((item, index) => (
          <div key={index} className={styles.list}>
            <a href={item[(lang + 'Url') as listItemKey]}>{t(item.name)}</a>
          </div>
        ))}
      </div>
      <div className={styles.mp3}>
        <div className={styles.title}>{t('Audio to MP3 Converter')}</div>
        {audioUrlList.map((item, index) => (
          <div key={index} className={styles.list}>
            <a href={item[(lang + 'Url') as listItemKey]}>{t(item.name)}</a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DropList
