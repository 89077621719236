import { FC, useState } from 'react'
import { Popover } from 'antd-mobile'
import useTranslation from 'next-translate/useTranslation'
import classnames from 'classnames'
import { sizeLists } from '@/pages/index'

import styles from './index.module.scss'
import { saClick } from '../../utils/sensors'

interface sizeListProps {
  sizeIndex: number
  setSizeIndex: React.Dispatch<React.SetStateAction<number>>
}

const QualityList: FC<sizeListProps> = ({ sizeIndex, setSizeIndex }) => {
  const { t }: { t: I18nType } = useTranslation('common')
  const [tipVisible, setTipVisible] = useState(false)

  return (
    <div className={styles.qualityList}>
      <div className={styles.formatLabel}>
        <Popover
          visible={tipVisible}
          overlay={
            <Popover.Item className={styles.popoverItem}>
              <div className={styles.tips}>{t('Audio tips')}</div>
            </Popover.Item>
          }
          placement={'bottomLeft'}
        >
          <div
            className="iconfont icon-tips-default iconTips"
            onMouseEnter={() => setTipVisible(true)}
            onMouseLeave={() => setTipVisible(false)}
          ></div>
        </Popover>
        <span>{t('Audio Quality')}</span>
      </div>
      <div className={styles.content}>
        <div className={styles.sizeList}>
          {sizeLists.map((e, i) => (
            <div
              key={i}
              className={styles.item}
              onClick={() => {
                setSizeIndex(i)
                saClick('core', 'bit_rate', `${i + 1}`)
              }}
            >
              <div className={styles.circleWrapper}>
                <div
                  className={classnames({
                    [styles.circle]: true,
                    [styles.active]: i <= sizeIndex,
                    [styles.selected]: i === sizeIndex,
                  })}
                ></div>
              </div>
              <div className={styles.name}>
                <div className={styles.label}>{t(e.label as any)}</div>
                <div className={styles.size}>{e.size}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.bgLine}>
          <div
            className={styles.active}
            style={{
              width: `${(100 / (sizeLists.length - 1)) * sizeIndex}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default QualityList
