import React, { DragEventHandler, FC, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import FileItem from './components/FileItem'
import { eventBus } from '@/utils/eventBus'

import styles from './index.module.scss'
import { saClick } from '../../utils/sensors'
import Modal from './components/FileItem/components/Modal'

type FileUploadProps = {
  fileList: File[]
  onChange: (files: FileList) => void
  setFileList: React.Dispatch<React.SetStateAction<File[]>>
}

const FileUploadA: FC<FileUploadProps> = ({ fileList, onChange, setFileList }) => {
  const { t }: { t: I18nType } = useTranslation('common')
  const [showDrop, setShowDrop] = useState(false)
  const chooseRef = useRef<HTMLDivElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [showWarnTips, setShowWarnTips] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    eventBus.on('file-warn-tips', eventListener)

    setIsMobile(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
        navigator.userAgent
      )
    )

    return () => {
      eventBus.removeListener('file-warn-tips', eventListener)
    }
  }, [])

  useEffect(() => {
    if (showWarnTips) {
      setTimeout(() => {
        eventBus.emit('file-warn-tips', false)
      }, 3000)
    }
  }, [showWarnTips])

  function eventListener(bool: boolean) {
    setShowWarnTips(bool)
  }

  const handleClick = () => {
    fileInputRef.current?.click()

    saClick('core', fileList.length ? 'upload' : 'local', fileList.length ? 'upload' : 'choose')
  }

  const handleSelect = () => {
    const select = fileInputRef.current
    if (select) {
      handleFile(select.files)
    }
  }

  const dragEnter: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    chooseRef.current?.classList.add('dragover')
    setShowDrop(true)
  }

  const dragLeave: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    chooseRef.current?.classList.remove('dragover')
    setShowDrop(false)
  }

  const dragOver: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const drop: DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    e.stopPropagation()
    const fileList = e.dataTransfer.files
    chooseRef.current?.classList.remove('dragover')
    setShowDrop(false)
    handleFile(fileList)

    saClick('core', 'local', 'drop')
  }

  const handleFile = (e: FileList | null) => {
    if (!e) return
    console.log('fileList', e)
    onChange(e)

    for (let i = 0; i < e.length; i++) {
      const el = e[i]
      if (!el.type.startsWith('audio/') && !el.type.startsWith('video/')) {
        // eventBus.emit('file-warn-tips', true)
        setShowModal(true)
      }
    }
  }

  return (
    <div className={styles.fileUpload}>
      <div className={styles.step}>1</div>
      <div className={styles.content}>
        <div
          className={styles.dropArea}
          style={{
            borderColor: showDrop ? '#ff5500' : '#fff8f5',
          }}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDragOver={dragOver}
          onDrop={drop}
        >
          <div className={styles.chooseFile} ref={chooseRef}>
            <div className={styles.text} onClick={handleClick}>
              {t(fileList.length ? 'Upload More' : 'Choose Local Files')}
            </div>
            <div className={styles.line} />
            <img className={styles.icon} src={require('./image/ic_local.png').default} alt="" onClick={handleClick} />
            {/* <img
              className={styles.icon}
              src={require('./image/ic_dropbox.png').default}
              alt=""
              onClick={() => console.log('ic_dropbox')}
            />
            <img
              className={styles.icon}
              src={require('./image/ic_drive.png').default}
              alt=""
              onClick={() => console.log('ic_drive')}
            />
            <img
              className={styles.icon}
              src={require('./image/ic_link.png').default}
              alt=""
              onClick={() => console.log('ic_link')}
            /> */}
            <input
              type="file"
              multiple
              className={styles.fileInput}
              ref={fileInputRef}
              onChange={handleSelect}
              accept="audio/*,video/*"
            />
          </div>
          <div className={styles.dropText}>{t('Or Drag Files Here')}</div>
        </div>
        {showWarnTips ? (
          <div className={styles.warnTipsWrap}>
            <div className={styles.warnTips}>
              <img className={styles.icon} src={require('./image/ic_warn.png').default} alt="" />
              {t('Some files wrong')}
            </div>
          </div>
        ) : null}
        {!isMobile ? (
          <div className={styles.fileList}>
            {fileList.map((e, i) => (
              <FileItem
                key={`${e.name}-${e.size}`}
                name={e.name}
                size={e.size}
                file={e}
                onDelete={() => {
                  const flist = Array.from(fileList)
                  flist.splice(i, 1)
                  setFileList(flist)
                }}
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className={styles.mContent}>
        {!fileList.length && (
          <div
            className={styles.chooseFile}
            style={{
              background: fileList.length ? 'linear-gradient(180deg, #FAFAFA 23.44%, #EEEEEE 100%)' : '#ff5500',
            }}
          >
            <div
              className={styles.text}
              style={{
                color: fileList.length ? '#333333' : '#ffffff',
              }}
              onClick={handleClick}
            >
              {t('Choose Files')}
            </div>
            <img
              className={styles.icon}
              style={{
                borderColor: fileList.length ? '#D8D8D8' : '#ffffff',
              }}
              src={require(`./image/ic_local${fileList.length ? '1' : ''}.png`).default}
              alt=""
              onClick={() => console.log('ic_link')}
            />
          </div>
        )}
        {isMobile ? (
          <>
            <div style={{ position: 'relative' }}>
              <div className={styles.fileList}>
                {fileList.map((e, i) => (
                  <FileItem
                    key={`${e.name}-${e.size}`}
                    name={e.name}
                    size={e.size}
                    file={e}
                    onDelete={() => {
                      const flist = Array.from(fileList)
                      flist.splice(i, 1)
                      setFileList(flist)
                    }}
                  />
                ))}
                {fileList.length > 3 && <div className={styles.gradient}></div>}
              </div>
            </div>
            {!!fileList.length && (
              <div className={styles.uploadMore} onClick={handleClick}>
                Upload More +
              </div>
            )}
          </>
        ) : null}
      </div>
      {showModal ? (
        <Modal
          onOk={() => {
            setShowModal(false)
          }}
          onClose={() => {
            setShowModal(false)
          }}
          title={t('Select Failed title')}
          content={t('Select Failed Tips')}
        />
      ) : null}
    </div>
  )
}

export default FileUploadA
