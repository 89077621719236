
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { useEffect, useState } from "react"
import { GetServerSideProps, NextPage } from "next"
import Head from "next/head"
import useTranslation from "next-translate/useTranslation"
import classnames from "classnames"
import Header from "@/pagesComp/index/components/Header"
import Footer from "@/pagesComp/index/components/Footer"
import Faq from "@/pagesComp/index/components/Faq"
import Feature from "@/pagesComp/index/components/Feature"
import FormatList from "@/pagesComp/index/components/FormatList"
import QualityList from "@/pagesComp/index/components/QualityList"
import FileUpload from "@/pagesComp/index/components/FileUpload"
import FileItem, { ConvertDataStatus } from "@/pagesComp/index/components/FileUpload/components/FileItem"
import FileItemB from "@/pagesComp/index/components/FileUploadB/components/FileItem"
import Tutorial from "@/pagesComp/index/components/Tutorial"

import styles from "./index.module.scss"
import { eventBus } from "@/utils/eventBus"
import { saClick } from "@/pagesComp/index/utils/sensors"
import FileUploadA from "@/pagesComp/index/components/FileUploadA"
import FileUploadB from "@/pagesComp/index/components/FileUploadB"
import FileUploadC from "@/pagesComp/index/components/FileUploadC"
import { Popover } from "antd-mobile"
import nookies from "nookies"

export const formats = [
  {
    label: "MP3",
    value: "MP3",
    targetValue: "mp3",
  },
  {
    label: "Ringtone for Android",
    value: "Ringtone for Android",
    targetValue: "mp3",
  },
  {
    label: "Ringtone for iPhone",
    value: "Ringtone for iPhone",
    targetValue: "mp3",
  },
  {
    label: "Audio for PC",
    value: "Audio for PC",
    targetValue: "mp3",
  },
  {
    label: "M4A",
    value: "M4A",
    targetValue: "m4a",
  },
]

export const sizeLists = [
  {
    label: "quality low",
    size: "64 kbps",
    value: 64,
  },
  {
    label: "quality good",
    size: "128 kbps",
    value: 128,
  },
  {
    label: "quality high",
    size: "320 kbps",
    value: 320,
  },
]

const Index: NextPage<{ plan: "A" | "B" | "C" }> = ({ plan }) => {
  console.log("plan: ", plan)
  const { t }: { t: I18nType } = useTranslation("common")
  const [isConvert, setIsConvert] = useState(false)
  const [fileList, setFileList] = useState<File[]>([])
  const [isAllFailed, setIsAllFailed] = useState(false)
  const [formatIndex, setFormatIndex] = useState(0)
  const [sizeIndex, setSizeIndex] = useState(0)
  const [showWarnTips, setShowWarnTips] = useState(false)
  const [isMobile, setIsMobile] = useState(true)
  const [formatIndexArr, setFormatIndexArr] = useState<number[]>([])
  const [qualityIndexArr, setQualityIndexArr] = useState<number[]>([])
  const [hasSelectFormat, setHasSelectFormat] = useState(false)
  const [optionVisible, setOptionVisible] = useState(false)
  const [uploadStatus, setUploadStatus] = useState(false)

  useEffect(() => {
    eventBus.on("file-warn-tips", eventListener)
    window.MP3_AB_INDEX_PLAN = plan

    setIsMobile(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
        navigator.userAgent
      )
    )

    return () => {
      eventBus.removeListener("file-warn-tips", eventListener)
    }
  }, [])

  useEffect(() => {
    if (showWarnTips) {
      setTimeout(() => {
        eventBus.emit("file-warn-tips", false)
      }, 3000)
    }
  }, [showWarnTips])

  useEffect(() => {
    const bool = [...fileList].every(e => !(e.type.startsWith("audio/") || e.type.startsWith("video/")))
    setIsAllFailed(bool)
    if (plan === "C" && !fileList.length) {
      setHasSelectFormat(false)
      setFormatIndex(0)
    }
    if (fileList.length) {
      setOptionVisible(true)
    }
  }, [fileList])
  useEffect(() => {
    // 转换过程，x 掉全部文件，直接回到初始选择文件按钮
    if (isConvert && !fileList.length) setIsConvert(false)
  }, [isConvert, fileList])

  function eventListener(bool: boolean) {
    if (process.browser && window.innerWidth > 760) return

    setShowWarnTips(bool)
  }

  const fileListHandler = (e: FileList) => {
    const files: File[] = []
    fileList.forEach(e => {
      files.push(e)
    })

    function existFile(fList: File[], file: File) {
      return fList.some(e => e.name === file.name && e.size === file.size)
    }

    // 去重处理
    for (const val of e) {
      if (!existFile(files, val)) {
        files.push(val)
      }
    }
    if (plan === "B") {
      const initArr = Array(files.length).fill(0)
      setFormatIndexArr(old => [...old, ...initArr.slice(old.length)])
      setQualityIndexArr(old => [...old, ...initArr.slice(old.length)])
    }
    setFileList(files)
  }

  const onConvert = () => {
    if (!fileList.length) return
    if (isAllFailed) return

    // 过滤不符合格式的文件
    let temp1
    let temp2
    const list = Array.prototype.filter.call(fileList, (e, i) => {
      if (e.type.startsWith("audio/") || e.type.startsWith("video/")) {
        return true
      }
      // 安卓下筛选掉图片文件所选择的格式和码率
      temp1 = formatIndexArr.filter((_, index) => index !== i)
      temp2 = qualityIndexArr.filter((_, index) => index !== i)
      return false
    })
    if (temp1 && temp2) {
      setFormatIndexArr((temp1 as unknown) as number[])
      setQualityIndexArr((temp2 as unknown) as number[])
    }
    setFileList(list as any)
    setIsConvert(true)
    window.scrollTo(0, 0)

    saClick("core", "button", "convert", { time: list.length })
  }

  const convertMore = () => {
    setIsConvert(false)
    setFileList([])
    setFormatIndexArr([])
    setQualityIndexArr([])

    saClick("core", "button", "conv_more")
  }
  // 文件状态为 UPLOADING 或 CONVERTING 不显示 convert more 按钮
  const statusFn = (status: ConvertDataStatus) => {
    if (status === ConvertDataStatus.UPLOADING || status === ConvertDataStatus.CONVERTING) {
      setUploadStatus(true)
    } else {
      setUploadStatus(false)
    }
  }

  const planCompMap = {
    // ABC 实验组
    A: (
      <>
        {!isConvert ? (
          <>
            <FileUploadA fileList={fileList} onChange={fileListHandler} setFileList={setFileList} />
            {!!fileList.length && (
              <>
                <FormatList formatIndex={formatIndex} setFormatIndex={setFormatIndex} />
                <QualityList sizeIndex={sizeIndex} setSizeIndex={setSizeIndex} />
                <div className={styles.convertBtn} onClick={onConvert}>
                  <div
                    className={fileList.length && !isAllFailed ? "iconfont icon-convert w" : "iconfont icon-convert"}
                  ></div>

                  <div
                    className={styles.text}
                    style={{
                      color: fileList.length && !isAllFailed ? "#fff" : "#D8D8D8",
                    }}
                  >
                    {t("Convert")}
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className={styles.fileList}>
              {fileList.map((e, i) => (
                <FileItem
                  key={`${e.name}-${e.size}`}
                  name={e.name}
                  size={e.size}
                  file={e}
                  isUpload
                  format={formats[formatIndex].targetValue}
                  quality={sizeLists[sizeIndex].value}
                  onDelete={() => {
                    const flist = Array.from(fileList)
                    flist.splice(i, 1)
                    setFileList(flist)
                  }}
                />
              ))}
            </div>
            <div className={styles.convertMoreBtn} onClick={convertMore}>
              <div className="iconfont icon-reload"></div>
              <div className={styles.text}>{t("Convert more files")}</div>
            </div>
          </>
        )}
      </>
    ),
    B: (
      <>
        {!isConvert ? (
          <>
            <FileUploadB
              fileList={fileList}
              onChange={fileListHandler}
              setFileList={setFileList}
              formatIndexArr={formatIndexArr}
              setFormatIndexArr={setFormatIndexArr}
              qualityIndexArr={qualityIndexArr}
              setQualityIndexArr={setQualityIndexArr}
            />
            {!!fileList.length && (
              <div
                style={{
                  width: "100%",
                  paddingBottom: "80px",
                  background: "#f5f5f5",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className={styles.convertBtn} onClick={onConvert}>
                  <div
                    className={fileList.length && !isAllFailed ? "iconfont icon-convert w" : "iconfont icon-convert"}
                  ></div>
                  <div
                    className={styles.text}
                    style={{
                      color: fileList.length && !isAllFailed ? "#fff" : "#D8D8D8",
                    }}
                  >
                    {t("Convert")}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.fileList}>
              {fileList.map((e, i) => (
                <FileItemB
                  key={`${e.name}-${e.size}`}
                  name={e.name}
                  size={e.size}
                  file={e}
                  format={formats[formatIndexArr[i]]?.targetValue}
                  quality={sizeLists[qualityIndexArr[i]]?.value}
                  isUpload
                  onDelete={() => {
                    const flist = Array.from(fileList)
                    flist.splice(i, 1)
                    setFileList(flist)
                  }}
                />
              ))}
            </div>
            <div className={styles.convertMoreBtn} onClick={convertMore}>
              <div className="iconfont icon-reload"></div>
              <div className={styles.text}>{t("Convert more files")}</div>
            </div>
          </>
        )}
      </>
    ),
    C: (
      <div className={styles.planC}>
        {!isConvert ? (
          <>
            <div className={styles.stepWrapper}>
              {!fileList.length && <div className={styles.step}>1</div>}
              <FileUploadC fileList={fileList} onChange={fileListHandler} setFileList={setFileList} />
              {!fileList.length && (
                <p className={styles.tips}>Click the button to choose the files you want to convert</p>
              )}
            </div>
            <div className={styles.stepWrapper}>
              <div
                className={styles.step}
                style={
                  !fileList.length || hasSelectFormat
                    ? {
                        color: "#d8d8d8",
                        borderColor: "#d8d8d8",
                      }
                    : {}
                }
              >
                2
              </div>
              <Popover
                placement="bottom"
                overlay={formats.map(item => (
                  <Popover.Item key={item.label}>{item.value}</Popover.Item>
                ))}
                onSelect={(node, index) => {
                  if (index !== undefined) {
                    setHasSelectFormat(true)
                    setFormatIndex(index)
                    saClick("core", "format", `${index + 1}`)
                  }
                }}
              >
                <button
                  className={styles.convertTo}
                  disabled={!fileList.length}
                  style={{
                    background:
                      !fileList.length || hasSelectFormat
                        ? "linear-gradient(180deg, #FAFAFA 23.44%, #EEEEEE 100%)"
                        : "linear-gradient(180deg, #FF5500 23.44%, #E14B00 100%)",
                  }}
                >
                  <div
                    className={styles.text}
                    style={{
                      color: fileList.length ? (hasSelectFormat ? "#333" : "#fff") : "#D8D8D8",
                    }}
                  >
                    {hasSelectFormat ? formats[formatIndex].value : t("Convert to")}
                  </div>
                  <div
                    className={styles.icon}
                    style={{
                      borderColor: fileList.length ? "#D8D8D8" : "#ffffff",
                    }}
                  >
                    <span
                      className={styles.arrow}
                      style={{ borderColor: fileList.length ? (hasSelectFormat ? "#333" : "#fff") : "#D8D8D8" }}
                    ></span>
                  </div>
                </button>
              </Popover>
            </div>
            <div className={styles.stepWrapper}>
              <div
                className={styles.step}
                style={
                  fileList.length && hasSelectFormat && !isAllFailed
                    ? {}
                    : {
                        color: "#d8d8d8",
                        borderColor: "#d8d8d8",
                      }
                }
              >
                3
              </div>
              <div
                className={styles.convertBtn}
                onClick={() => {
                  if (!hasSelectFormat) return
                  onConvert()
                }}
              >
                <div
                  className={
                    fileList.length && hasSelectFormat && !isAllFailed
                      ? "iconfont icon-convert w"
                      : "iconfont icon-convert"
                  }
                ></div>

                <div
                  className={styles.text}
                  style={{
                    color: fileList.length && hasSelectFormat && !isAllFailed ? "#fff" : "#D8D8D8",
                  }}
                >
                  {t("Convert now")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.fileList}>
              {fileList.map((e, i) => (
                <FileItem
                  key={`${e.name}-${e.size}`}
                  name={e.name}
                  size={e.size}
                  file={e}
                  isUpload
                  format={formats[formatIndex].targetValue}
                  quality={sizeLists[sizeIndex].value}
                  onDelete={() => {
                    const flist = Array.from(fileList)
                    flist.splice(i, 1)
                    setFileList(flist)
                  }}
                />
              ))}
            </div>
            <div className={styles.convertMoreBtn} style={{ margin: "0 auto" }} onClick={convertMore}>
              <div className="iconfont icon-reload"></div>
              <div className={styles.text}>{t("Convert more files")}</div>
            </div>
          </>
        )}
      </div>
    ),
    // 对照组，原方案
    D: !isConvert ? (
      <>
        <FileUpload fileList={fileList} onChange={fileListHandler} setFileList={setFileList} onConvert={onConvert} />
        <FormatList formatIndex={formatIndex} setFormatIndex={setFormatIndex} />
        <QualityList sizeIndex={sizeIndex} setSizeIndex={setSizeIndex} />
        {!!fileList.length && (
          <div className={styles.convertBtn} onClick={onConvert}>
            <div className="iconfont icon-convert w"></div>
            <div className={styles.text}>{t("Convert")}</div>
          </div>
        )}
      </>
    ) : (
      <>
        <div className={styles.fileList}>
          {fileList.map((e, i) => (
            <FileItem
              key={`${e.name}-${e.size}`}
              name={e.name}
              size={e.size}
              file={e}
              isUpload
              format={formats[formatIndex].targetValue}
              quality={sizeLists[sizeIndex].value}
              updateParentStatus={(status: ConvertDataStatus) => {
                statusFn(status)
              }}
              onDelete={() => {
                const flist = Array.from(fileList)
                flist.splice(i, 1)
                setFileList(flist)
              }}
            />
          ))}
        </div>

        <div
          className={styles.convertMoreBtn}
          onClick={convertMore}
          style={{ display: uploadStatus ? "none" : "flex" }}
        >
          <div className="iconfont icon-convert w phone"></div>
          <div className={styles.text}>{t("Convert more files")}</div>
        </div>
      </>
    ),
  }

  return (
    <div className={styles.index}>
      <Head>
        <title>{t("meta-title")}</title>
        <meta name="description" content={t("meta-description")} />
        <meta name="keywords" content={t("meta-keywords")} />
        <meta property="og:title" content={t("meta-title")} />
        <meta property="og:description" content={t("meta-description")} />
        <meta property="og:image" content="https://www.online-audioconvert.com/convert-logo.png" />
      </Head>
      <Header />
      {showWarnTips ? (
        <div className={styles.warnTips}>
          <img className={styles.icon} src={require("../pagesComp/index/image/ic_warn.png").default} alt="" />
          {t("Some files wrong")}
        </div>
      ) : null}
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>{t("Files to MP3 Online")}</h1>
          <h2>{t("Header Desc")}</h2>
        </div>
        {!isMobile ? (
          !isConvert ? (
            <div className={styles.func}>
              <FileUpload
                fileList={fileList}
                onChange={fileListHandler}
                setFileList={setFileList}
                onConvert={onConvert}
              />
              <div className={styles.optionHeader}>
                <div
                  className={classnames({
                    [styles.formatHeader]: true,
                    [styles.showOptions]: optionVisible,
                  })}
                  onClick={() => setOptionVisible(!optionVisible)}
                >
                  <div className={styles.formatTxt}>
                    <span className="iconfont icon-setting"></span>
                    {t("Audio Options")}
                  </div>
                  <div className={optionVisible ? "iconfont icon-down active" : "iconfont icon-down"}></div>
                </div>
              </div>
              {optionVisible && (
                <>
                  <FormatList formatIndex={formatIndex} setFormatIndex={setFormatIndex} />
                  <QualityList sizeIndex={sizeIndex} setSizeIndex={setSizeIndex} />
                </>
              )}
            </div>
          ) : (
            <>
              {/* 桌面端>转码 */}
              <div className={`${styles.func} ${styles.fileList}`}>
                <div className={styles.fileListContent}>
                  {fileList.map((e, i) => (
                    <FileItem
                      key={`${e.name}-${e.size}`}
                      name={e.name}
                      size={e.size}
                      file={e}
                      isUpload
                      format={formats[formatIndex].targetValue}
                      quality={sizeLists[sizeIndex].value}
                      updateParentStatus={(status: ConvertDataStatus) => {
                        statusFn(status)
                      }}
                      onDelete={() => {
                        const flist = Array.from(fileList)
                        flist.splice(i, 1)
                        setFileList(flist)
                      }}
                    />
                  ))}
                </div>
              </div>
              <div
                className={styles.convertMoreBtn}
                onClick={convertMore}
                style={{ display: uploadStatus ? "none" : "flex" }}
              >
                <div className="iconfont icon-convert w"></div>
                <div className={styles.text}>{t("Convert more files")}</div>
              </div>
            </>
          )
        ) : (
          planCompMap[plan]
        )}
      </div>
      <Tutorial style={["A", "B"].includes(plan) ? { background: "#fff" } : {}} isMobile={isMobile} />
      <Feature />
      <Faq />
      <Footer />
    </div>
  )
}

export default Index

 const _getServerSideProps: GetServerSideProps = async context => {
  const { req } = context
  console.log("req.headers: ", req.headers)
  console.log("req.headers.cookie", req.cookies)
  const plan = req.cookies["X-Mp3-Experiment-Plan1"]
  nookies.set(context, "X-Mp3-Experiment-Plan1", plan)
  return {
    props: {
      // 最终用回原方案
      plan: "D",
    }, // will be passed to the page component as props
  }
}


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  