import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import styles from './index.module.scss'

// const icons: I18nItemType[] = ['converter', 'fast', 'devices', 'security', 'strong', 'easy']
const icons: I18nItemType[] = ['fast', 'devices', 'security']

const Feature: FC = () => {
  const { t }: { t: I18nType } = useTranslation('common')

  const featureArr = icons.map(item => ({
    src: require('../../../../images/' + item + '.png').default,
    alt: item,
    title: t(item),
    desc: t(`${item} desc` as I18nItemType),
  }))

  return (
    <div className={styles.feature}>
      <h2 className={styles.mainTitle}>{t('Feature title')}</h2>
      <div className={styles.featureBox}>
        {featureArr.map(e => (
          <div className={styles.singleFeature} key={e.title}>
            <div className={styles.title}>
              <img src={e.src} alt={e.alt} />
              <h4>{e.title}</h4>
            </div>
            <div className={styles.desc}>{e.desc}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Feature
