/* eslint-disable jsx-a11y/alt-text */
import React, { FC, useEffect, useRef, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import styles from './index.module.scss'
import { saClick } from '../../utils/sensors'

interface TutorialProps {
  isMobile?: boolean
  style?: React.CSSProperties
}

const Tutorial: FC<TutorialProps> = ({ style, isMobile = false }) => {
  const { t, lang }: { t: I18nType; lang: string } = useTranslation('common')
  return (
    <div className={styles.tutorial} style={{ ...style }}>
      <h2 className={styles.title}>{t('Tutorial')}</h2>
      <div className={styles.content}>
        <div className={styles.step}>
          <div className={styles.stepImage}>
            <img src={require(`./image/step-1-${lang}.png`).default}></img>
          </div>
          <div className={styles.stepTxt}>
            <div className={styles.stepProgress}>{t('tutorial step1')}</div>
            <h3 className={styles.stepTile}>{t('tutorial step1 title')}</h3>
            <div className={styles.stepDesc}>{t('tutorial step1 desc')}</div>
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.stepImage}>
            <img src={require(`./image/step-2-${lang}.png`).default}></img>
          </div>
          <div className={styles.stepTxt}>
            <div className={styles.stepProgress}>{t('tutorial step2')}</div>
            <h3 className={styles.stepTile}>{t('tutorial step2 title')}</h3>
            <div className={styles.stepDesc}>{t('tutorial step2 desc')}</div>
          </div>
        </div>
        <div className={styles.step}>
          <div className={styles.stepImage}>
            <img src={require(`./image/step-3-${lang}.png`).default}></img>
          </div>
          <div className={styles.stepTxt}>
            <div className={styles.stepProgress}>{t('tutorial step3')}</div>
            <h3 className={styles.stepTile}>{t('tutorial step3 title')}</h3>
            <div className={styles.stepDesc}>{t('tutorial step3 desc')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tutorial
