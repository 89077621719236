import React, { FC, useEffect, useRef, useState } from 'react'
import { formatFileSize } from '@/pagesComp/index/utils'
import Uppy from '@uppy/core'
import UppyAwsS3 from '@uppy/aws-s3'
import JsCookie from 'js-cookie'
import io from '@/utils/io'
import useTranslation from 'next-translate/useTranslation'
import classnames from 'classnames'
import Modal from './components/Modal'
import styles from './index.module.scss'
import { saClick, saOthers } from '@/pagesComp/index/utils/sensors'

const getFileName = (location: string): string => {
  return (/^https:\/\/s3.amazonaws.com\/([a-z0-9-]+)\/([a-zA-Z0-9-_]+)/.exec(location) as RegExpExecArray)[2]
}

type ConvertData = {
  fileKey: string
  //转码进度，范围为 0-1000
  progress: number
  //转码后视频的下载地址
  downloadUrl: string
  //当前状态，值为下列中的一个
  status: ConvertDataStatus
  //错误描述
  message: string
}

export enum ConvertDataStatus {
  SELECT_FAILED = 'SELECT_FAILED',
  NOT_STARTED = 'NOT_STARTED',
  UPLOADING = 'UPLOADING',
  CONVERTING = 'CONVERTING',
  SUCCEED = 'SUCCEED',
  FAILED = 'FAILED',
}

type FileItemProps = {
  name: string
  size: number
  file: File
  /**
   * 是否上传。false - 显示 ready 状态，只用来展示文件。true - 调用上传接口
   */
  isUpload?: boolean
  format?: string
  quality?: number
  onDelete: () => void
  updateParentStatus?: (status: ConvertDataStatus) => void
}

const FileItem: FC<FileItemProps> = ({ name, size, onDelete, isUpload, file, format, quality, updateParentStatus }) => {
  const { t }: { t: I18nType } = useTranslation('common')
  const [percent, setPercent] = useState(0)
  const fileKeyRef = useRef('')
  const fileNameRef = useRef('')
  const fileTypeRef = useRef('')
  const fileUrlRef = useRef('')
  const [status, setStatus] = useState<ConvertDataStatus>(ConvertDataStatus.NOT_STARTED)
  const uppyRef: React.MutableRefObject<Uppy.Uppy<Uppy.TypeChecking>> = useRef<any>(null)
  const convertInfoTimeoutRef = useRef<any>(null)
  const [showModal, setShowModal] = useState(false)
  const uploadTimeRef = useRef(0)
  const convertTimeRef = useRef(0)

  useEffect(() => {
    // 本来想着选中组件后直接上传
    // 不过实际应该是点 convert 后才上传
    // 一开始的 preparing xx% 感觉不需要，可以直接显示 ready
    // 然后上传时再将上传进度设置成转换进度，接口提示成功后再显示 download 按钮
    uppyRef.current = Uppy({
      restrictions: {
        allowedFileTypes: null,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
      },
      autoProceed: true,
      infoTimeout: 1000 * 60 * 2,
    })

    uppyRef.current.use(UppyAwsS3, {
      getUploadParameters: async ({ name, type }) => {
        const { data } = await io.post('/api/presigned', {
          type,
          filename: name,
        })

        return data
      },
    })

    uppyRef.current.on('upload-progress', onProgress)

    uppyRef.current.on('upload', onUpload)

    uppyRef.current.on('upload-success', onUploadSuceess)

    uppyRef.current.on('upload-error', onUploadError)

    uppyRef.current.on('complete', onComplete)

    if (isUpload) {
      const { name, type } = file
      try {
        uppyRef.current.addFile({
          id: 'FileUpload',
          data: file,
          name,
          type,
        })
      } catch (error) {
        setShowModal(true)
        console.error(`File "${name}" upload failed with error: ${error}`)
      }
    }

    return () => {
      uppyRef.current.off('upload-progress', onProgress)
      uppyRef.current.off('upload', onUpload)
      uppyRef.current.off('upload-success', onUploadSuceess)
      uppyRef.current.off('upload-error', onUploadError)
      uppyRef.current.off('complete', onComplete)

      clearTimeout(convertInfoTimeoutRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!file.type.startsWith('audio/') && !file.type.startsWith('video/')) {
      setStatus(ConvertDataStatus.SELECT_FAILED)
      saOthers('failed', 'file', file.type)
    }
  }, [file])

  const onProgress = (file: any, progress: any) => {
    setPercent(Number(((progress.bytesUploaded / progress.bytesTotal) * 100).toFixed(0)))
  }

  const onUpload = () => {
    setStatus(ConvertDataStatus.UPLOADING)

    // 记录开始上传的时间
    uploadTimeRef.current = Date.now()
  }

  const onUploadSuceess = (file: any, { uploadURL }: any) => {
    const { name, type } = file

    const key = getFileName(uploadURL)
    // setFileKey(key)
    fileKeyRef.current = key
    fileNameRef.current = name
    fileTypeRef.current = type

    // 提前流转成 convert 状态，避免 uploading 100%，以为卡住了
    setStatus(ConvertDataStatus.CONVERTING)
    setPercent(0)

    saOthers('upload', formatFileSize(size), `${Math.floor((Date.now() - uploadTimeRef.current) / 1000)}`)
  }

  const uploadErrRef = useRef(false)
  const onUploadError = ({ name }: any, error: any) => {
    if (error.isNetworkError) {
      // https://github.com/transloadit/uppy/issues/2245
      // This looks like a network error, the endpoint might be blocked by an internet provider or a firewall.
      console.log('ISP error')
      return
    }
    if (!uploadErrRef.current) {
      setStatus(ConvertDataStatus.FAILED)
      saOthers('failed', 'upload', '')

      uploadErrRef.current = true
      setShowModal(true)
      console.error(`File "${name}" upload failed with error: ${error}`)
    }
  }

  const onComplete = () => {
    if (!uploadErrRef.current) {
      goConvert()
    }

    uppyRef.current.reset()
  }

  // 发起转码请求
  const goConvert = async () => {
    convertTimeRef.current = Date.now()

    await io.post<ConvertData>(
      `https://api.${process.env.NEXT_PUBLIC_ENV !== 'production' ? 'staging.' : ''}mp3converting.com/convert`,
      {
        //存储在 S3 中的文件名，也是标识某次任务的字段
        fileKey: fileKeyRef.current,
        //视频在本地的文件名，用于下载时命名
        fileName: fileNameRef.current,
        //转码格式
        format,
        //视频原格式
        sourceFormat: fileTypeRef.current.split('/')[1],
        //视频码率
        bitrate: quality,
      }
    )

    convertInfoTimeoutRef.current = setTimeout(() => {
      getConvertInfo()
    })
  }

  // 轮询转换转码结果
  const getConvertInfo = async () => {
    const {
      data: { status: fetchStatus, progress, downloadUrl },
    } = await io.get<ConvertData>(
      `https://api.${process.env.NEXT_PUBLIC_ENV !== 'production' ? 'staging.' : ''}mp3converting.com/convert`,
      {
        params: {
          //存储在 S3 中的文件名，也是标识某次任务的字段
          fileKey: fileKeyRef.current,
        },
      }
    )

    // 开始转码
    switch (fetchStatus) {
      case ConvertDataStatus.CONVERTING:
        setStatus(fetchStatus)
        setPercent(progress)
        break
      case ConvertDataStatus.SUCCEED:
        clearTimeout(convertInfoTimeoutRef.current)
        convertInfoTimeoutRef.current = null
        fileUrlRef.current = downloadUrl
        setStatus(fetchStatus)
        setPercent(0)

        saOthers('convert', '', `${Math.floor((Date.now() - convertTimeRef.current) / 1000)}`)
        break
      case ConvertDataStatus.FAILED:
        clearTimeout(convertInfoTimeoutRef.current)
        convertInfoTimeoutRef.current = null
        fileUrlRef.current = downloadUrl
        setStatus(fetchStatus)
        saOthers('failed', 'convert', '')
        setPercent(0)
        setShowModal(true)
        break
      default:
        break
    }

    if (convertInfoTimeoutRef.current) {
      convertInfoTimeoutRef.current = setTimeout(() => {
        getConvertInfo()
      }, 1000)
    }
  }

  const renderStatus = (convertStatus: ConvertDataStatus) => {
    updateParentStatus && updateParentStatus(convertStatus)
    switch (convertStatus) {
      case ConvertDataStatus.SELECT_FAILED:
        return <div className={styles.selectFailed}>{t('Failed')}</div>
      case ConvertDataStatus.NOT_STARTED:
        return <div className={styles.ready}>{t('Ready')}</div>
      case ConvertDataStatus.UPLOADING:
        return (
          <div className={styles.uploading}>
            <div className={styles.text}>{`${t('Uploading')} ${percent}%`}</div>
            <div className={styles.progress} style={{ width: `${percent}%` }} />
          </div>
        )
      case ConvertDataStatus.CONVERTING:
        return (
          <div className={styles.converting}>
            <div className={styles.text}>{`${t('Converting')} ${percent}%`}</div>
            <div className={styles.progress} style={{ width: `${percent}%` }} />
          </div>
        )
      case ConvertDataStatus.SUCCEED:
        return (
          <div className={styles.download} onClick={onDownload}>
            <div className={styles.text}>{t('Download')}</div>
          </div>
        )
      case ConvertDataStatus.FAILED:
        return <div className={styles.failed}>{t('Failed')}</div>
      default:
        break
    }
  }

  const onDownload = () => {
    if (process.browser) {
      const sensorsData = JsCookie.get('sensorsdata2015jssdkcross') || '{}'
      const { distinct_id } = JSON.parse(sensorsData)

      io.post('/api/sensors/track', {
        distinct_id,
        event: 'mp3converting_click',
        property: {
          action: 'core',
          label: 'button',
          event_name: 'download',
          index_plan: window.MP3_AB_INDEX_PLAN,
        },
      })

      window.location.href = fileUrlRef.current
    }
  }

  return (
    <>
      <div className={styles.fileItemWrap}>
        <div className={styles.fileItem}>
          <div className={styles.name}>{name}</div>
          {!isUpload ? (
            <>
              <div
                className={classnames({
                  [styles.status]: true,
                  [styles.upload]: isUpload,
                })}
              >
                {renderStatus(status)}
              </div>
              <div className={styles.size}>{formatFileSize(size)}</div>
            </>
          ) : (
            <>
              <div className={styles.size} style={{ marginLeft: 0 }}>
                {formatFileSize(size)}
              </div>
              <div
                className={classnames({
                  [styles.status]: true,
                  [styles.upload]: isUpload,
                })}
                style={{ marginLeft: 0 }}
              >
                {renderStatus(status)}
              </div>
              <img
                className={styles.delUpload}
                style={{
                  visibility: status === ConvertDataStatus.SUCCEED ? 'hidden' : 'visible',
                }}
                src={require('./image/cancel.png').default}
                alt="del"
                onClick={() => {
                  uppyRef.current.cancelAll()
                  onDelete()

                  saClick(
                    'core',
                    'close',
                    status === ConvertDataStatus.UPLOADING
                      ? 'uploading'
                      : status === ConvertDataStatus.CONVERTING
                      ? 'converting'
                      : status === ConvertDataStatus.FAILED
                      ? uploadErrRef.current
                        ? 'failed_upload'
                        : 'failed_convert'
                      : 'unknow1'
                  )
                }}
              />
            </>
          )}
          {!isUpload ? (
            <img
              className={styles.del}
              src={require('./image/cancel.png').default}
              alt="del"
              onClick={() => {
                onDelete()
                saClick(
                  'core',
                  'close',
                  status === ConvertDataStatus.NOT_STARTED
                    ? 'ready'
                    : status === ConvertDataStatus.SELECT_FAILED
                    ? 'failed'
                    : 'unknow'
                )
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.mFileItemWrap}>
        {!isUpload ? (
          <div className={styles.mFileItemReady}>
            <div className={styles.name}>{name}</div>
            <div
              className={classnames({
                [styles.status]: true,
                [styles.upload]: isUpload,
              })}
            >
              {renderStatus(status)}
            </div>
            <div className={styles.size}>{formatFileSize(size)}</div>
            <img
              className={styles.del}
              src={require('./image/cancel.png').default}
              alt="del"
              onClick={() => {
                onDelete()
                saClick(
                  'core',
                  'close',
                  status === ConvertDataStatus.NOT_STARTED
                    ? 'ready'
                    : status === ConvertDataStatus.SELECT_FAILED
                    ? 'failed'
                    : 'unknow'
                )
              }}
            />
          </div>
        ) : (
          <div className={styles.mFileItemUpload}>
            <div className={styles.info}>
              <div className={styles.name}>{name}</div>
              <div className={styles.size}>{formatFileSize(size)}</div>
            </div>
            <div className={styles.status}>{renderStatus(status)}</div>
            <img
              className={styles.del}
              style={{
                visibility: status === ConvertDataStatus.SUCCEED ? 'hidden' : 'visible',
              }}
              src={require('./image/cancel.png').default}
              alt="del"
              onClick={() => {
                uppyRef.current.cancelAll()
                onDelete()

                saClick(
                  'core',
                  'close',
                  status === ConvertDataStatus.UPLOADING
                    ? 'uploading'
                    : status === ConvertDataStatus.CONVERTING
                    ? 'converting'
                    : status === ConvertDataStatus.FAILED
                    ? uploadErrRef.current
                      ? 'failed_upload'
                      : 'failed_convert'
                    : 'unknow1'
                )
              }}
            />
          </div>
        )}
      </div>
      {showModal ? (
        <Modal
          onOk={() => {
            setShowModal(false)
          }}
          onClose={() => {
            setShowModal(false)
          }}
          title={t('Task Failed title')}
          content={t('Task Failed Tips')}
        />
      ) : null}
    </>
  )
}

FileItem.defaultProps = {
  isUpload: false,
}

export default FileItem
