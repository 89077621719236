import React, { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import styles from './index.module.scss'

const texts: I18nItemType[] = ['Q-one', 'Q-two', 'Q-three', 'Q-four']

const Faq: FC = () => {
  const { t }: { t: I18nType } = useTranslation('common')
  const faqArr = texts.map(item => ({
    title: t(item),
    desc: t(`${item} desc` as I18nItemType),
  }))

  return (
    <div className={styles.faqContainer}>
      <h2 className={styles.title}>{t('QA title')}</h2>
      <div className={styles.faqBox}>
        {faqArr.map(e => (
          <div className={styles.singleFaq} key={e.title}>
            <h4>{e.title}</h4>
            <div className={styles.text}>{e.desc}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Faq
