import sa from '@/utils/sa'

/**
 * 点击事件
 */
export function saClick(action: string, label: string, event_name: string, extra: any = {}) {
  sa.track('mp3converting_click', {
    action,
    label,
    event_name,
    ...extra,
  })
}

/**
 * 时长事件
 */
export function saOthers(action: string, label: string, event_name: string, extra: any = {}) {
  sa.track('mp3converting_others', {
    action,
    label,
    event_name,
    ...extra,
  })
}
